import React, { useEffect, useState } from "react";
import {format} from "date-fns";
import {Link} from "react-router-dom";
interface PromoCode {
    senderBank: string;
    receiverBank: string;
    id: number;
    createdAt: string;
    direction: string;
    amount: number;
    status: number;
}
const History = () => {
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);

  useEffect(() => {
    // Проверка наличия Telegram Web Apps SDK
    if (window.Telegram?.WebApp?.initDataUnsafe) {
        const id = window.Telegram.WebApp.initDataUnsafe.user?.id;
        if (id) {
            setUserId(id);
            const fetchPromoCodes = async () => {
                try {
                    const response = await fetch(
                        "https://cryptospace.kz/api/history?userId=" + id
                    );

                    if (!response.ok) {
                        throw new Error(`Ошибка сети: ${response.status}`);
                    }

                    const data: PromoCode[] = await response.json();
                    setPromoCodes(data); // Сохраняем данные в состоянии массива
                } catch (error) {
                    console.error("Ошибка при загрузке данных:", error);
                }
            };

            fetchPromoCodes();
        }
        else{
            setUserId(123)
        }
    }
  }, []);


  return (
      <>
        <div className="history_block">
          <div className="wrapper">
            <div className="history_items">
              {promoCodes.map((item, index) => (
                  <div className="history_item" key={index}>
                      <Link to="/transfer-details" state={item}>
                          <div className="history_item_title">
                              {item.status === 0 ? (
                                  <div className="history_item_title_processing">
                                      <span>в обработке</span>
                                      <span>   <span><i className="fa fa-credit-card" aria-hidden="true"></i></span>                  </span>
                                  </div>
                              ) : (
                                  <div className="history_item_title">
                                      {format(new Date(item.createdAt), "dd.MM.yyyy HH:mm")}
                                  </div>
                              )
                              }

                          </div>
                          <div className="history_item_row">
                              <div className="history_item_ico">
                              <i className="fa fa-credit-card" aria-hidden="true"></i>
                              </div>
                              <div className="history_item_desc">
                                  <p>с карты {item.senderBank}</p>
                                  <p>на карту {item.receiverBank}</p>
                              </div>
                              {item.direction === 'KZ' ?
                                  <div className="history_item_sum">
                                      <p>{item.amount} руб.</p>
                                      <p>{item.amount * 5} тнг.</p>
                                  </div>
                                  : <div className="history_item_sum">
                                      <p>{item.amount * 5} тнг.</p>
                                      <p>{item.amount} руб.</p>


                                  </div>}


                          </div>
                      </Link>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </>
  );
};

export default History;