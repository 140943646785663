import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Simulate} from "react-dom/test-utils";
import {useNavigate} from "react-router-dom";

const PaymentSender = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState<number | undefined>(0);

    useEffect(() => {
        // Проверка наличия Telegram Web Apps SDK
        if (window.Telegram?.WebApp?.initDataUnsafe) {
            const id = window.Telegram.WebApp.initDataUnsafe.user?.id;
            setUserId(id);
        } else {
            alert("Telegram Web Apps SDK не инициализирован");
        }
    }, []);


    const location = useLocation();
    const {paymentData} = location.state || {};
    const [card, setCard] = useState('');
    const handleCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCard(e.target.value);
    };
    const copyToClipboardCard = async () => {
        await navigator.clipboard.writeText('2202 2080 1799 9450');
    };
    const copyToClipboardPrice = async () => {
        console.log(paymentData.totalAmount)
        await navigator.clipboard.writeText(paymentData.totalAmount);
    };
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const amount = parseInt(paymentData.amount, 10);

        // Проверяем `userId`
        try {
            const data = {
                direction: paymentData.direction,
                senderBank: paymentData.selectedBankFrom,
                receiverBank: paymentData.selectedBankTo,
                amount: parseInt(paymentData.amount),
                receiverCardNumber: card,
                status: 0,
                userId: userId?.toString() || "123",
                createdAt: new Date().toISOString()
            };
            const response = await fetch('https://cryptospace.kz/api/create-transfer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data), // данные для отправки
            });
            if (!response.ok) {
                alert('Network response was not ok');
                throw new Error('Network response was not ok');
            }
            const json = await response.json();
            navigate("/transfer-details", {state: json});
        } catch (error) {
            alert('error: ' + error);
        }
    };
    return (
        <>
            <div className="transfer">
                <div className="wrapper">
                    <div className="sum_desc">
                        <p className="sum_title">Вы переводите:</p>
                        <p className="sum_numb">{paymentData.totalAmount.toFixed(2)} {` ${paymentData.direction === 'RU' ? 'тнг' : 'руб'}`}</p>
                        <p className="sum_title">Получателю поступит на карту:</p>
                        <p className="sum_numb">{paymentData.receivedAmount.toFixed(2)} {` ${paymentData.direction === 'RU' ? 'руб' : 'тнг'}`}</p>
                        <p className="sum_title sum_title_mt">
                            Комиссия за
                            перевод: <strong>{parseInt(paymentData.amount) / 50} {` ${paymentData.direction === 'RU' ? 'тнг' : 'руб'}`}.</strong>
                        </p>
                        <p className="sum_title">

                            Курс
                            обмена: <strong> {` ${paymentData.direction === 'RU' ? '5 KZT' : '1 RUB'}`} = {` ${paymentData.direction === 'RU' ? '1 RUB ' : '5 KZT'}`}</strong>
                        </p>
                    </div>
                    <form>
                        <div className="transfer_input">
                            <h2>Номер карты получателя</h2>
                            <input type="text" inputMode="numeric" onChange={handleCardChange}/>
                        </div>
                        <div className="transfer_sum">
                            <div className="transfer_sum_title">Переведите</div>
                            <div className="transfer_sum_numb" >
                                <span id="copy_sum">{paymentData.totalAmount}</span>
                            </div>
                            <div className="transfer_sum_copy" id="copy_sum_btn" onClick={() => {
                                copyToClipboardPrice()
                            }}><i className="fa fa-files-o"
                                                                                    aria-hidden="true"></i></div>
                            <div className="transfer_sum_subtitle">на карту:</div>
                        </div>
                        <div className="transfer_card ">

                            <div className="transfer_card_row">
                                <div className="transfer_card_numb">
                                    <span id="card_numb">2202 2080 1799 9450</span>
                                </div>
                                <div className="transfer_card_copy" id="copy_numb" onClick={() => {
                                    copyToClipboardCard()
                                }}><i className="fa fa-files-o" aria-hidden="true"></i></div>
                            </div>
                        </div>
                        <div className="transfer_approval">
                            <label>
                                <input type="checkbox" id="approval" defaultChecked required/>
                                <span>
                  Я подтверждаю что выполнил перевод на указанную выше карту.
                </span>
                            </label>
                        </div>
                        <div className="transfer_btns">
                            <a onClick={handleSubmit} className="btn btn_black">
                                Завершить перевод
                            </a>
                            <a href="/" className="btn btn_white">
                                Назад
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default PaymentSender;
