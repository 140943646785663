import React, {useState} from 'react';
import {BrowserRouter, Link} from 'react-router-dom';

const TransferDirectionTabs = () => {
    const [selectedBankFrom, setSelectedBankFrom] = useState<string>(''); // ID выбранного банка "Откуда"
    const [selectedBankTo, setSelectedBankTo] = useState<string>(''); // ID в
    const [direction, setDirection] = useState<'RU' | 'KZ'>('RU');
    const [amount, setAmount] = useState(''); // Храним значение как строку
    const exchangeRate = 5; // Курс обмена RUB -> KZT

    // Обработчик изменения суммы перевода
    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
            setAmount(value);
        }
    };
    // Преобразуем введенное значение в число для расчетов, если оно не пустое
    const amountValue = parseFloat(amount) || 0;
    const receivedAmount = direction === 'RU'
        ? amountValue / exchangeRate
        : amountValue * exchangeRate;

    const totalAmount = amountValue + (amountValue / 50);

    // Списки банков для разных стран
    const kazakhstanBanks = [
        {id: 1, name: 'Kaspi', img: '/xberfront/img/bank5.svg'},
        {id: 2, name: 'Jusan', img: '/xberfront/img/bank6.svg'},
        {id: 3, name: 'RBK', img: '/xberfront/img/bank7.svg'},
        {id: 4, name: 'Halyk', img: '/xberfront/img/bank8.svg'}
    ];

    const russiaBanks = [
        {id: 1, name: 'T-Банк', img: '/xberfront/img/bank1.svg'},
        {id: 2, name: 'Сбер', img: '/xberfront/img/bank2.svg'},
        {id: 3, name: 'Райффайзен', img: '/xberfront/img/bank3.svg'},
        {id: 4, name: 'ВТБ', img: '/xberfront/img/bank4.svg'}
    ];
    const paymentData = {
        amount: amount,
        selectedBankFrom: selectedBankFrom,
        selectedBankTo:selectedBankTo,
        direction: direction,
        totalAmount:totalAmount,
        receivedAmount: receivedAmount
    };

    return (
        <>
            <div className={`direction ${direction === 'RU' ? 'direction_ru' : 'direction_kz'}`}>
                <div className="wrapper">
                    <h2>Направление перевода</h2>
                    <div className="direction_btns">
                        <div
                            className={`direction_btn ${direction === 'RU' ? 'direction_btn_active' : ''} btn_kz`}
                            onClick={() => setDirection('RU')}
                        >
                            Переводы в Россию
                        </div>
                        <div
                            className={`direction_btn ${direction === 'KZ' ? 'direction_btn_active' : ''} btn_ru`}
                            onClick={() => setDirection('KZ')}
                        >
                            Переводы в Казахстан
                        </div>
                    </div>
                    {direction === 'RU' ? (
                        <div className="direction direction_ru">
                            <div className="wrapper">
                                <div className="direction_sliders">
                                    <div className="direction_slider">
                                        <div className="direction_slider_title"><span>Откуда</span><span></span></div>
                                        <div className="gallery_wrapper">
                                            <div className="gallery gallery1">
                                                <div className="slides">
                                                    <div className="gallery_item">
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Kaspi")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank5.svg" alt="image"/>
                                                                <div className="gallery_item_title">Kaspi</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Jusan")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank6.svg" alt="image"/>
                                                                <div className="gallery_item_title">Jusan</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("RBK")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank7.svg" alt="image"/>
                                                                <div className="gallery_item_title">RBK</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Halyk")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank8.svg" alt="image"/>
                                                                <div className="gallery_item_title">Halyk</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gallery_item">
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Kaspi")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank5.svg" alt="image"/>
                                                                <div className="gallery_item_title">Kaspi</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Jusan")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank6.svg" alt="image"/>
                                                                <div className="gallery_item_title">Jusan</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="navigation"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="direction_slider">
                                        <div className="direction_slider_title"><span></span><span>Куда</span></div>
                                        <div className="gallery_wrapper">
                                            <div className="gallery gallery2">
                                                <div className="slides">
                                                    <div className="gallery_item">
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("T-Банк")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank1.svg" alt="image"/>
                                                                <div className="gallery_item_title">T-Банк</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("Сбер")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank2.svg" alt="image"/>
                                                                <div className="gallery_item_title">Сбер</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("Райффайзен")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank3.svg" alt="image"/>
                                                                <div className="gallery_item_title">Райффайзен</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("ВТБ")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank4.svg" alt="image"/>
                                                                <div className="gallery_item_title">ВТБ</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gallery_item">
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("T-Банк")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank1.svg" alt="image"/>
                                                                <div className="gallery_item_title">T-Банк</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("Сбер")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank2.svg" alt="image"/>
                                                                <div className="gallery_item_title">Сбер</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="navigation"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="direction direction_kz">
                            <div className="wrapper">
                                <div className="direction_sliders">
                                    <div className="direction_slider">
                                        <div className="direction_slider_title"><span>Откуда</span><span>Куда</span>
                                        </div>
                                        <div className="gallery_wrapper">
                                            <div className="gallery gallery1">
                                                <div className="slides">
                                                    <div className="gallery_item">
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Halyk")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank5.svg" alt="image"/>
                                                                <div className="gallery_item_title">Kaspi</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Jusan")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank6.svg" alt="image"/>
                                                                <div className="gallery_item_title">Jusan</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("RBK")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank7.svg" alt="image"/>
                                                                <div className="gallery_item_title">RBK</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Halyk")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank8.svg" alt="image"/>
                                                                <div className="gallery_item_title">Halyk</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gallery_item">
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Kaspi")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank5.svg" alt="image"/>
                                                                <div className="gallery_item_title">Kaspi</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank2" onClick={() => {
                                                                setSelectedBankTo("Jusan")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank6.svg" alt="image"/>
                                                                <div className="gallery_item_title">Jusan</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="navigation"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="direction_slider">
                                        <div className="direction_slider_title"><span>Откуда</span><span>Куда</span>
                                        </div>
                                        <div className="gallery_wrapper">
                                            <div className="gallery gallery2">
                                                <div className="slides">
                                                    <div className="gallery_item">
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("T-Банк")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank1.svg" alt="image"/>
                                                                <div className="gallery_item_title">T-Банк</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("Сбер")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank2.svg" alt="image"/>
                                                                <div className="gallery_item_title">Сбер</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("Райффайзен")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank3.svg" alt="image"/>
                                                                <div className="gallery_item_title">Райффайзен</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("ВТБ")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank4.svg" alt="image"/>
                                                                <div className="gallery_item_title">ВТБ</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gallery_item">
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("T-Банк")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank1.svg" alt="image"/>
                                                                <div className="gallery_item_title">T-Банк</div>
                                                            </div>
                                                        </div>
                                                        <div className="gallery_item_col">
                                                            <input type="radio" name="bank1" onClick={() => {
                                                                setSelectedBankFrom("Cбер")
                                                            }}/>
                                                            <div className="gallery_item_col_content">
                                                                <img src="/img/bank2.svg" alt="image"/>
                                                                <div className="gallery_item_title">Сбер</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="navigation"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="sum">
                <div className="wrapper">
                    <h2>Сумма перевода</h2>
                    <div className="sum_input">
                        <input
                            type="number"
                            inputMode="numeric"
                            placeholder="0"
                            value={amount}
                            onChange={handleAmountChange}
                        />
                    </div>
                    <div className="sum_desc">
                        <p className="sum_title">Вы переводите:</p>
                        <p className="sum_numb">{totalAmount.toFixed(2)} {` ${direction === 'RU' ? 'тнг' : 'руб'}`}</p>
                        <p className="sum_title">Получателю поступит на карту:</p>
                        <p className="sum_numb">{receivedAmount.toFixed(2)} {` ${direction === 'RU' ? 'руб' : 'тнг'}`}</p>
                        <p className="sum_title sum_title_mt">
                            Комиссия за
                            перевод: <strong>{parseFloat(amount) / 50} {` ${direction === 'RU' ? 'тнг' : 'руб'}`}.</strong>
                        </p>
                        <p className="sum_title">

                            Курс
                            обмена: <strong> {` ${direction === 'RU' ? '5 KZT' : '1 RUB'}`} = {` ${direction === 'RU' ? '1 RUB ' : '5 KZT'}`}</strong>
                        </p>
                    </div>
                    <div className="sum_btn">
                        <Link to="/payment" state={{paymentData}} className="btn btn_black">
                            Перейти к переводу
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransferDirectionTabs;