import {useEffect, useState} from "react";
import Header from "./components/Header";
import TransferDirectionTabs from "./components/TransferDirectionTabs";
import './css/styles.css'
import BankSelector from "./components/BankSelector";
import {BrowserRouter as Router, Route, Routes, Link, BrowserRouter} from "react-router-dom";
import "./css/styles.css";
import "./js/script";
import PaymentSender from "./components/PaymentSender";
import History from "./components/History";
import FAQ from "./pages/faq";
import TransferDetails from "./pages/transer-details";
import "./font-awesome/css/font-awesome.css";

const App = () => {

    useEffect(() => {
        // Проверяем, доступен ли объект Telegram Web App
        const tg = (window as any).Telegram?.WebApp;

        if (tg && tg.themeParams) {
            const theme = tg.themeParams;

            // Устанавливаем цвета темы с использованием CSS-переменных
            document.documentElement.style.setProperty('--background-color', theme.bg_color || '#ffffff');
            document.documentElement.style.setProperty('--text-color', theme.text_color || '#000000');
        }
    }, []);

    return (
        <BrowserRouter>
            <>
                <Header/>
            </>
            <Routes>
                <Route path="/" element={<TransferDirectionTabs/>}/>
                <Route path="/payment" element={<PaymentSender/>}/>
                <Route path="/history" element={<History/>}/>
                <Route path="/faq" element={<FAQ/>}/>
                <Route path="/transfer-details" element={<TransferDetails/>}/>
            </Routes>
        </BrowserRouter>
    );
};
export default App;
