import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Список страниц, где нужно показывать стрелку назад
  const pagesWithBackArrow = ["/", "/payment", "/"];

  const showBackArrow = !pagesWithBackArrow.includes(location.pathname);

  return (
      <header className="header">
        <div className="wrapper">
          <div className="header_items">
            <div className="header_left">
              <div className="header_left_icons">
              {showBackArrow ? (
                  <button onClick={() => navigate(-1)} className="back-arrow">
                    {/* Иконка стрелки назад */}
                    <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
                  </button>    
                          
                    
              ) : (
                
                  <div className="logo">
                    <div className="logo_title">xber</div>
                    <div className="logo_desc">Ничего лишнего - просто переводы.</div>
                  </div>
              )}
              </div>
            </div>
            <div className="header_right">
              <a href="/history">
                <i className="fa fa-history" aria-hidden="true"></i>
              </a>
              <a href="/faq">
                <i className="fa fa-question-circle" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>

      </header>
  );
};

export default Header;