import React, { useState } from "react";

const FAQItem: React.FC<{ question: string; answer: string }> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="help_item">
            <div
                className="help_item_title"
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: 'pointer' }}
            >
                <span>{question}</span>
                <span>
          <i className={`fa fa-arrow-circle-${isOpen ? "up" : "down"}`} aria-hidden="true"></i>
        </span>
            </div>
            {isOpen && <div style={{ marginLeft: 20}}>{answer}</div>}
        </div>
    );
};

const FAQ: React.FC = () => {
    const faqItems = [
        { question: "Как это работает?", answer: "Для совершения трансграничных карточных переводов наш сервис использует карты верифицированных партнеров-мерчантов. Благодаря этому вы можете перевести деньги между Россией и Казахстаном всего в два клика." },
        { question: "Насколько это безопасно?", answer: "Наш сервис гарантируют безопасность ваших средств. Вы можете спокойно отправлять деньги на указанные сервисом реквизиты." },
        { question: "Как быстро приходит перевод?", answer: "Обычно это происходит моментально. В некоторых случаях возможны задержки до 15 минут, связанные с конкретными банками." },
        { question: "Насколько это законно?", answer: "Наша платформа имеет лицензию МФЦА на предоставление финансовых и платежных услуг." },
        { question: "У меня остались вопросы", answer: "Вы всегда можете обратиться в нашу службу поддержки или посетить официальную группу в Телеграм." },
    ];

    return (
        <div>


        <div className="help_block">
            <div className="wrapper">
                <div className="help_items">
                    {faqItems.map((item, index) => (
                        <FAQItem key={index} question={item.question} answer={item.answer} />
                    ))}
                </div>
            </div>
        </div>

        <div className="help_btns">
           
				<a href="#" target="_blank" className="btn btn_social">
                    <span><i className="fa fa-comments" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Служба поддержки</span>    
                </a>
            </div>

        </div>
    );
};

export default FAQ;